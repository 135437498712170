import Navbar from '../../common/Navbar.vue'
import Tabbar from '../common/Tabbar.vue'
import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
import '../../../../assets/icon/font-icon.js'
import { Tabs, Tab, Image, Icon, Tag, NoticeBar } from 'vant'
export default {
    components: {
        Navbar: Navbar,
        Tabbar: Tabbar,
        Loading: Loading,
        Share: Share,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Image.name]: Image,
        [Icon.name]: Icon,
        [Tag.name]: Tag,
        [NoticeBar.name]: NoticeBar
    },
    data () {
        return {
            activeTab: 'SEL',
            sellerCode: '',
            wallet: {},
            settleList: [],
            settleCount: { prior: 0, wait: 0, complete: 0 }
        }
    },
    mounted () {
        this.sellerCode = window.sessionStorage.getItem(this.SESSION_SELLER)
        this.retrieveSellerCount()
        this.retrieveSettlePage()
        this.retrieveWallet()
        this.$refs.share.default('seller')
    },
    methods: {
        async retrieveWallet () {
            const { data: res } = await this.$http.post(this.UMS_URL + '/customer/wallet/retrieveWallet')
            if (res.status === '200') {
                this.wallet = res.data
            }
        },
        async retrieveSellerCount () {
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { refereeCode: user, config: 'PRIOR,WAIT,COMPLETE' }
            const { data: res } = await this.$http.post(this.BMS_URL + '/settle/referee/retrieveStateSettleCount', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.settleCount.prior += res.data.prior
                this.settleCount.wait += res.data.wait
                this.settleCount.complete += res.data.complete
            }
        },
        async retrieveSettlePage () {
            this.loadingShow = true
            this.settleList = []
            var user = window.sessionStorage.getItem(this.SESSION_USER)
            var pd = { refereeCode: user, current: 1, size: 50 }
            const { data: res } = await this.$http.post(this.BMS_URL + '/settle/referee/retrieveSettlePage', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.settleList = res.data
            }
            this.loadingShow = false
        },
        record () {
            this.$router.push({ path: '/mde/wallet/cusRecord' })
        },
        extract () {
            this.$router.push({ path: '/mde/wallet/cusExtract' })
        },
        seller () {
            this.$router.push({ path: '/seller/login' })
        },
        tabChange (val) {
            this.activeTab = val
            if (val === 'SEL') {
                this.retrieveSettlePage()
            } else if (val === 'IVT') {
            }
        }
    }
}
